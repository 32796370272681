import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import LabelDo from '@images/a11y/Native-label-1.1-Do.png';
import LabelDont from '@images/a11y/Native-label-1.1-Dont.png';
import LabelNameDo from '@images/a11y/Native-label-2.1–Do.png';
import LabelNameDont from '@images/a11y/Native-label-2.1–Dont.png';
import LinkTextDo from '@images/a11y/Native-link-text-1.4-Do.png';
import LinkTextDont from '@images/a11y/Native-link-text-1.4-Dont.png';
import DecorativeImageDo from '@images/a11y/Native-decorative-image-1.5-Do.png';
import DecorativeImageDont from '@images/a11y/Native-decorative-image-1.5-Dont.png';
import TouchTargetDo from '@images/a11y/Native-touch-target-1.6-Do.png';
import TouchTargetDont from '@images/a11y/Native-touch-target-1.6-Dont.png';
import ColourAloneDO2 from '@images/a11y/2.4-Colour-Alone-DO_2.png';
import ColourAloneDONT2 from '@images/a11y/2.4-Colour-Alone-DONT_2.png';
import LabelInputsDo from '@images/a11y/PlaceholderText-DO.png';
import LabelInputsDont from '@images/a11y/PlaceholderText-DONT.png';
import HiddenLabelDO from '@images/a11y/1.5-Hidden-Label-DO.png';
import HiddenLabelDONT from '@images/a11y/1.5-Hidden-Label-DONT.png';
import ReadingFocusOrderDo from '@images/a11y/1.5-Reading-Focus-Order-DO.png';
import ReadingFocusOrderDont from '@images/a11y/1.5-Reading-Focus-Order-DONT.png';
import LargeFontDo from '@images/a11y/SupportLargeFont-DO1.png';
import LargeFontDont from '@images/a11y/SupportLargeFont-DONT1.png';
import ColourContrastDo from '@images/a11y/1.8-ColourContrast-DO.png';
import ColourContrastDont from '@images/a11y/1.8-ColourContrast-DONT.png';
import UIColourContrastDo from '@images/a11y/1.8-UIColourContrast-DO.png';
import UIColourContrastDont from '@images/a11y/1.8-UIColourContrast-DONT.png';
import TextAlternativesDO from '@images/a11y/1.2-Text-alternatives-DO.png';
import TextAlternativesDONT from '@images/a11y/1.2-Text-alternatives-DONT.png';
import TextAlternativesDO2 from '@images/a11y/1.2-Text-alternatives-DO_2.png';
import TextAlternativesDONT2 from '@images/a11y/1.2-Text-alternatives-DONT_2.png';
import TextAlternativesDO4 from '@images/a11y/1.2-Text-alternatives-DO_4.png';
import TextAlternativesDONT4 from '@images/a11y/1.2-Text-alternatives-DONT_4.png';
import { Accordion, AccordionItem } from '@jsluna/accordion';
import { default as CodeSnippet } from '@components/accessibility-sections/CodeSnippet';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Section = makeShortcode("Section");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Box = makeShortcode("Box");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#designing-for-ios-and-android"
            }}>{`Designing for iOS and Android`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#developing-for-ios-and-anroid"
            }}>{`Developing for iOS and Anroid`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <p>{`Whether you’re part of a team designing web experiences or part of a team building native iOS or Android experiences, we encourage you to work together to deliver accessible products.`}</p>
    <p>{`The following native standards are based on the `}<a href="https://support.google.com/accessibility/android/answer/6376559?hl=en-GB&ref_topic=6376582" target="_blank">{`Android Accessibility Scanner`}</a>{`and the `}<a href="https://developer.apple.com/design/human-interface-guidelines/foundations/accessibility/" target="_blank">{`Apple Human Interface Guidelines`}</a>{`.`}</p>
    <p>{`For support making products accessible or to provide some feedback, `}<a parentName="p" {...{
        "href": "#get-in-touch"
      }}>{`get in touch with us`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "designing-for-ios-and-android",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#designing-for-ios-and-android",
        "aria-label": "designing for ios and android permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Designing for iOS and Android`}</h2>
    <p>{`Make sure to follow these standards by annotating your designs with relevant accessibility information before you hand them over to the team building your native experiences.`}</p>
    <h3>{`Things to check`}</h3>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="1.1 Make your link text descriptive" mdxType="AccordionItem">
      <p>
        Don't use vague or generic link text. Links shouldn't need supporting
        copy to make sense.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <h3>Your orders</h3>
          <p>Manage your sheduled orders.</p>
          <a href="#">See your orders</a>
        </Do>
        <Dont mdxType="Dont">
          <h3>Your orders</h3>
          <p>Manage your sheduled orders.</p>
          <a href="#">Tap here</a>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.2 Don't convey information using only colour" mdxType="AccordionItem">
      <p>
        Use additional visual cues in combination with colour to convey
        information so users with vision impairments, or who are colourblind,
        can have difficulty distinguishing colours.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={ColourAloneDO2} alt="A form field that has a red error message directly below, that includes an icon, and a text explanation for the error" />
        </Do>
        <Dont mdxType="Dont">
          <img src={ColourAloneDONT2} alt="A form field that uses a red outline around the field border to show something is wrong" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.3 Factor in large font settings into your designs" mdxType="AccordionItem">
      <p>
        All text should also be able to be resized using the largest browser
        font-size setting without losing information or meaning.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Speak to the team implementing your design work about how your
            designs will need to respond to large font settings
          </p>
          <img src={LargeFontDo} alt="two buttons, one with smaller text one with bigger text" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Don't assume all layouts and elements will work with large font
            settings
          </p>
          <img src={LargeFontDont} alt="The button with larger text is poorly formatted, the word action is split across two lines and the spacing around the text is incorrect." />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.4 Check the colour contrast meets WCAG 2.1 AA" mdxType="AccordionItem">
      <p>
        As a minimum, we follow{' '}
        <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank">
          WCAG 2.1 AA contrast ratios
        </a>
        .
      </p>
      <p>
        To check if the colour contrast of your elements meets the AA standard,
        the{' '}
        <a href="https://webaim.org/resources/contrastchecker/" target="_blank">
          WebAIM colour contrast checker
        </a>{' '}
        is one of the many tools available to check colour contrast.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={ColourContrastDo} alt="Dark text on a white background with a contrast ratio of 21:1" />
        </Do>
        <Dont mdxType="Dont">
          <img src={ColourContrastDont} alt="Light grey text on a white background with a contrast ratio of 1.42:1" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={UIColourContrastDo} alt="Dark outlined checkbox component on a white background with a contrast ratio of 21:1" />
        </Do>
        <Dont mdxType="Dont">
          <img src={UIColourContrastDont} alt="Harder to see light outlined checkbox component on a white background with a contrast ratio of 21:1" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.5 Ensure all touch targets meet minimum requirements" mdxType="AccordionItem">
      <p>
        Ensure all interactive elements are no smaller than 48x48dp, or 32x32dp
        for elements within an input method window.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={TouchTargetDo} alt="A password input field with a hide/show control measuring 48x48dp, and an input keyboard showing that the w key measures 32x32dp." />
        </Do>
        <Dont mdxType="Dont">
          <img src={TouchTargetDont} alt="A password input field with a hide/show control measuring 24x24dp." />
        </Dont>
      </Guideline>
    </AccordionItem>
  </Accordion>
    </Section>
    <h3>{`Things to annotate`}</h3>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="1.6 Give text alternatives for icons and images" mdxType="AccordionItem">
      <p>
        When there is no supporting text, provide text alternatives for images
        and icons to convey meaning for screen reader users.
      </p>
      <p>
        Hide decorative images that are not needed by screen readers to
        understand content.
      </p>
      <p>
        If you're not sure whether an image needs a text alternative, you can{' '}
        <a href="https://www.w3.org/WAI/tutorials/images/decision-tree/" target="_blank">
          refer to the W3C alt decision tree
        </a>{' '}
        for help.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Annotate text alternatives for images and icons when there is no
            supporting text for the image or icon
          </p>
          <img src={TextAlternativesDO} alt="A design of a retail product card including text alternative annotations" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without text alternatives the meaning of your images and icons can
            be lost or misinterpreted
          </p>
          <img src={TextAlternativesDONT} alt="A design of a retail product card with no text alternative annotations" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <img src={TextAlternativesDO2} alt="Heart icon with a text alternative annotation of add to wishlist" />
        </Do>
        <Dont mdxType="Dont">
          <img src={TextAlternativesDONT2} alt="Heart icon with no text alternative annotations" />
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            The delivery van is decorative, it's not needed for users to
            understand the adjacent information so it can be hidden from screen
            reader users
          </p>
          <img src={TextAlternativesDO4} alt="A delivery van image with the adjacent text, Save up to £168 per year, Pay no delivery charges 7 days a week" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Don't leave it up to others to guess if an image should or shouldn't
            have a text alternative.
          </p>
          <img src={TextAlternativesDONT4} alt="A design of a retail product card with no text alternative annotations" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.7 Always provide a visible form field label" mdxType="AccordionItem">
      <p>
        Form fields should have ever present labels to help users avoid errors
        when entering data. Placeholder text disappears when a user starts
        typing so should not be used as the only source of information to the
        user.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>The form field label is ever present</p>
          <img src={LabelInputsDo} alt="Form field a label of First Name visible above the field, with the placeholder text, Example: Mary-Ann" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without a form field label, the instruction in the placeholder text
            will disappear once a user interact with the field.
          </p>
          <img src={LabelInputsDont} alt="Form field with placeholder text, First name" />
        </Dont>
      </Guideline>
      <p>
        Input fields should always be accompanied with an visible label.
        However, if it isn't possible to display a visible label then ensure you
        provide an appropriate hidden label for assistive technologies like
        screen readers so they can understand and use the field.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>Provide an annotation for hidden labels</p>
          <img src={HiddenLabelDO} alt="Form field with no visible label with placeholder text, search our products... There is an annotation for the hidden label" />
        </Do>
        <Dont mdxType="Dont">
          <p>Don't leave it up to the developer to figure out what you want</p>
          <img src={HiddenLabelDONT} alt="Form field with no visible label with placeholder text, search our products... There is no annotation for the form field label" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.8 Communicate the intended focus order" mdxType="AccordionItem">
      <p>
        Annotate the focus order of elements where there is ambiguity about what
        the order should be.
      </p>
      <p>
        Users who use screen readers, switch controls or USB keyboards rely on
        there being a logical order to experiences or they may interpret
        information in the wrong context where there is ambiguity.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>Annotate the intended reading and focus order in your designs</p>
          <img src={ReadingFocusOrderDo} alt="A product card clearly showing the intended reading and focus order of the elements within it" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without annotations the intended flow of content can be interpreted
            incorrectly
          </p>
          <img src={ReadingFocusOrderDont} alt="A product card with no indication of the intended reading and focus order of the elements within it" />
        </Dont>
      </Guideline>
    </AccordionItem>
  </Accordion>
    </Section>
    <hr></hr>
    <h2 {...{
      "id": "developing-for-ios-and-anroid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#developing-for-ios-and-anroid",
        "aria-label": "developing for ios and anroid permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Developing for iOS and Anroid`}</h2>
    <p>{`Using native iOS and Android SDK components over custom alternatives will save development time needed to make custom components accessible.`}</p>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="2.1 Ensure all non-text based UI elements have an accessible name" mdxType="AccordionItem">
      <p>
        Ensure all user interface elements that receive screen reader focus have
        an accessible name that can be used by assisted technologies.
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Box hasCodeSnippet title="iOS" mdxType="Box">
          <p className="ln-u-soft-sides">
            The <code>.accessibilityAddTraits</code> attribute allows authors to
            change the semantic behaviour of an element. The element's
            accessible name in this instance comes from the{' '}
            <code>.accessibilityLabel</code> attribute.
          </p>
          <CodeSnippet noOverflow codeFormat="clike" code={`   /* SwiftUI */
                       Image(...) .accessibilityAddTraits([.isButton]) .accessibilityLabel("Menu") 
                       
                       /* UIKit */
                       let imageView = UIImageView(...)
                       imageView.accessibilityTraits = .button
                       imageView.accessibilityLabel = “Menu” 
                       `} mdxType="CodeSnippet" />
        </Box>
        <Box hasCodeSnippet title="Android" mdxType="Box">
          <p className="ln-u-soft-sides">
            In most cases this is done using the <code>contentDescription</code>{' '}
            attribute. For more information read Android's documentation{' '}
            <a href="https://developer.android.com/guide/topics/ui/accessibility/apps#describe-ui-element" target="_blank">
              Describe each UI element
            </a>
            .
          </p>
          <CodeSnippet codeFormat="html" code={` <ImageView
          android:clickable="True"
          android:focusable="True"
          android:focusableInTouchMode="True"
          android:screenReaderFocusable="True"
          android:contentDescription="Menu" /> `} mdxType="CodeSnippet" />
        </Box>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.2 Remove redundant type or state informatinon from element name" mdxType="AccordionItem">
      <p>
        Element type and state information wil be communicated when an element
        has the correct semantics. Don't include type and state infomration in
        an elements accessible name.
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Box hasCodeSnippet title="iOS" mdxType="Box">
          <p className="ln-u-soft-sides">
            The element's type <code>button</code> is automatically announced by
            the accessibility service and should not be included in the
            element's namespace.
          </p>
          <CodeSnippet codeFormat="clike" code={` Button("Add to Basket") {
            ...   
            } `} mdxType="CodeSnippet" />
        </Box>
        <Box hasCodeSnippet title="Android" mdxType="Box">
          <p className="ln-u-soft-sides">
            The element's type, <code>button</code> is automatically announced
            by the accessibility service and should not be included in the
            element's <code>contentDescription</code>.
          </p>
          <CodeSnippet codeFormat="html" code={`<Button
          android:contentDescription="Add to basket" />`} mdxType="CodeSnippet" />
        </Box>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.3 Ensure decorative images are hidden from accessibility services" mdxType="AccordionItem">
      <p>
        Remove decorative images from the accessibility tree. Check with your
        design team if an image should be decorative or not.
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Box hasCodeSnippet title="iOS" mdxType="Box">
          <p className="ln-u-soft-sides">
            Setting the{' '}
            <a href="https://developer.apple.com/documentation/objectivec/nsobject/1615141-isaccessibilityelement" target="_blank">
              .isAccessibilityElement
            </a>{' '}
            attribute on the image to <code>false</code> tells accessibility
            services to ignore it.
          </p>
          <CodeSnippet codeFormat="clike" code={`
                       /* SwiftUI */
                       Image(...)
                       .isAccessibilityElement = false
                       
                       /* UIKit */
                       imageView.isAccessibilityElement = false `} mdxType="CodeSnippet" />
        </Box>
        <Box hasCodeSnippet title="Android" mdxType="Box">
          <p className="ln-u-soft-sides">
            Setting the{' '}
            <a href="https://developer.android.com/reference/android/view/View#isImportantForAccessibility()" target="_blank">
              importantForAccessibility
            </a>{' '}
            attribute on the <code>ImageView</code> to "no" tells accessibility
            services to ignore it.
          </p>
          <CodeSnippet codeFormat="html" code={`
                       <ImageView 
          android:importantForAccessibility="no" /> `} mdxType="CodeSnippet" />
        </Box>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.4 Avoid duplicate clickable views" mdxType="AccordionItem">
      <p>
        To avoid confusing accessibility services that use focus ensure
        clickable views do not have a clickable ancestor or descendent in the
        same location.
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Box hasCodeSnippet title="iOS" mdxType="Box">
          <p className="ln-u-soft-sides">
            The <code>imageView</code> has two <code>subView</code> elements.
            The <code>subView</code> element's user interaction and
            accessibility traits have been removed, only the parent{' '}
            <code>imageView</code> is available to accessibility services.
          </p>
          <CodeSnippet codeFormat="clike" code={`
                       let subView = UIView()
                       imageView.addSubview(subView)
                       subView.isUserInteractionEnabled = false
                       subView.accessibilityTraits = .none `} mdxType="CodeSnippet" />
        </Box>
        <Box hasCodeSnippet title="Android" mdxType="Box">
          <p className="ln-u-soft-sides">
            The <code>ImageView</code> has had it's accessibility support
            explicitly removed, only the <code>Button</code> is clickable and
            can receive focus.
          </p>
          <CodeSnippet codeFormat="html" code={`
                       <FrameLayout>
          <ImageView
          android:layout_width="48dp"
          android:layout_height="48dp"
          android:clickable="false"
          android:focusable="false"
          android:focusableInTouchMode="false"
          android:screenReaderFocusable="false"
          />
          <Button
          android:layout_width="48dp"
          android:layout_height="48dp"
          android:clickable="true"
          />
          </FrameLayout> `} mdxType="CodeSnippet" />
        </Box>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.5 Ensure EditText and editable text views have a name" mdxType="AccordionItem">
      <p>
        Always provide an accessible name for input fields so that screen reader
        users can understand the purpose of the field.
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Box hasCodeSnippet title="iOS" mdxType="Box">
          <CodeSnippet codeFormat="clike" code={`
                       /* SwiftUI */
                       TextField.accessibilityLabel = “Your first name” 
                       
                       /* UIKit */
                       let usernameTextField = UITextField(...)
                       usernameTextField.accessibilityLabel = “Username Input Field” `} mdxType="CodeSnippet" />
        </Box>
        <Box hasCodeSnippet title="Android" mdxType="Box">
          <CodeSnippet codeFormat="html" code={`
                       <EditText
          android:contentDescription="Your first name" /> `} mdxType="CodeSnippet" />
        </Box>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.6 Ensure all custom views are semantically correct" mdxType="AccordionItem">
      <p>
        Ensure custom views are semantically correct by assigning appropriate
        class, type and state properties. System-provided elements typically
        have all of the appropriate accessibility semanticts built in as
        standard
      </p>
      <Guideline hasCodeSnippet mdxType="Guideline">
        <Box title="iOS" mdxType="Box">
          <p className="ln-u-soft-sides">
            For more information on creating custom SwiftUI or UIKit components
            refer to apple's accessibility documentation{' '}
            <a href="https://developer.apple.com/documentation/uikit/accessibility_for_uikit" target="_blank">
              Accessibility for UIKit
            </a>
            .
          </p>
        </Box>
        <Box hasCodeSnippet title="Android" mdxType="Box">
          <p className="ln-u-soft-sides">
            For more information on extending system-provided widgets refer
            Android's Developer documentation on{' '}
            <a href="https://developer.android.com/guide/topics/ui/accessibility/principles#system-widgets" target="_blank">
              Extending system widgets
            </a>
            .
          </p>
        </Box>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="2.7 Ensure elements follow the intended focus and reading order" mdxType="AccordionItem">
      <p>Typically this will be top to bottom and left to right.</p>
      <p>
        For more complex layouts and where their is any ambiguity, consult the
        design team on the intended focus order of elements.
      </p>
    </AccordionItem>
    <AccordionItem title="2.8 Ensure text and text container elements support text scaling" mdxType="AccordionItem">
      <p>
        All text should be resizable (using the operating system font settings)
        without the text being clipped, overlapped or obscured.
      </p>
      <p>
        It may not be possible to scale some navigational elements but these
        should be treated as an exception. Likewise, truncation will occure but
        try to keep this to a minimum.
      </p>
      {
            /*      <Guideline hasCodeSnippet>
             <Box hasCodeSnippet title="iOS">
               <p class="ln-u-soft-sides">
                 The iOS system <code>Text Styles</code> automatically support
                 dynamic type text scalling. For more information on using{' '}
                 <code>Text Styles</code> and support for custom fonts read Apple's
                 documentation{' '}
                 <a
                   href="https://developer.apple.com/documentation/uikit/uifont/scaling_fonts_automatically"
                   target="_blank"
                 >
                   Scaling Fonts Automatically
                 </a>
                 .
               </p>
               <CodeSnippet
                 codeFormat="clike"
                 code={`
                            label.font = UIFont.preferredFont(forTextStyle: .body)
                            label.adjustsFontForContentSizeCategory = true `}
               />
             </Box>
             <Box hasCodeSnippet title="Android">
               <CodeSnippet
                 codeFormat="html"
                 code={`
                            <Button
               android:textSize="20sp" ... />`}
               />
             </Box>
            </Guideline> */
          }
    </AccordionItem>
    <AccordionItem title="2.9 Announce errors, ststus changes and content updates to screen readers" mdxType="AccordionItem">
      <p>
        An unexpected change in content or layout can be very confusing to
        screen reader users, because it means that their mental map of the
        screen is no longer accurate. It’s crucial to report onscreen changes to
        screen readers who may not see newly injected content and layout
        changes.
      </p>
      {
            /*      <Guideline hasCodeSnippet>
             <Box hasCodeSnippet title="iOS">
               <CodeSnippet
                 codeFormat="clike"
                 code={` ??? `}
               />
             </Box>
             <Box hasCodeSnippet title="Android">
               <CodeSnippet codeFormat="html" code={`
                            <TextView 
               id="@+id/email_error_state"
               android:importantForAccessibility="yes" />`}
               />
             </Box>
            </Guideline> */
          }
    </AccordionItem>
    <AccordionItem title="2.10 Ensure all touch targets meet minimum requirements" mdxType="AccordionItem">
      <p>
        Ensure all clickable elements are no smaller than 48x48dp or 32x32dp for
        elements within an input method window or against the display edge
      </p>
      {
            /*      <Guideline hasCodeSnippet>
             <Box hasCodeSnippet title="iOS">
               <CodeSnippet
                 codeFormat="clike"
                 code={`
                            Button.frame.x = 48
                            Button.frame.y = 48 `}
               />
             </Box>
             <Box hasCodeSnippet title="Android">
               <CodeSnippet
                 codeFormat="html"
                 code={`
                            <Button 
               android:minWidth="48dp"
               android:minHeight="48dp" /> `}
               />
             </Box>
            </Guideline> */
          }
    </AccordionItem>
  </Accordion>
    </Section>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions about web accessibility or help you with
  your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      